
import { Inject } from 'inversify-props';

import Currency from '@/modules/common/components/currency.vue';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import type Day from '@/modules/common/types/day.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import UserService, { UserServiceS } from '../../../../user/user.service';

import BaseCard from './base-card.vue';

@Component({
    filters: { PercentFilter, PriceFilter },
    components: {
        CalendarEventsContainer,
        Currency,
        RatesDayScanBtn,
        BaseCard,
    },
})

export default class AvailableFilterAll extends Vue {
    @Inject(DocumentFiltersServiceS)
    documentFiltersService!: DocumentFiltersService;

    @Inject(RatesServiceS)
    ratesService!: RatesService;

    @Inject(RatesAllServiceS)
    ratesAllService!: RatesAllService;

    @Inject(UserServiceS)
    userService!: UserService;

    @Inject(RatesCommonServiceS)
    ratesCommonService!: RatesCommonService;

    @Inject(CompsetsServiceS)
    compsetsService!: CompsetsService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    cardColorClass() {
        const color = this.ratesCommonService.getCardAssessment(this.diff, this.compsetsService.currentCompset!);

        switch (color) {
            case ASSESSMENTS_TYPES.GOOD:
                return 'green';
            case ASSESSMENTS_TYPES.NORMAL:
                return 'yellow';
            case ASSESSMENTS_TYPES.BAD:
                return 'red';
            default:
                return 'gray';
        }
    }

    get highestPrice() {
        return this.ratesAllService.getHighestPrice(this.day);
    }

    get diff() {
        return (this.highestPrice - this.averagePrice) / this.averagePrice;
    }

    get averagePrice() {
        return this.ratesAllService.getAverageRoomsPrice(this.day);
    }

    get currency() {
        return this.ratesService.storeState.document && (this.ratesService.storeState.document.currency || null);
    }
}
